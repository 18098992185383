import React from "react"
import Layout from "../components/layout"
import styled from "styled-components"
import video from "../assets/fish.mp4"
import SEO from "../components/seo"
import BackgroundVideo from "../components/BackgroundVideo"

export default () => (
  <Layout>
    <SEO title="Contact | Clapesque" />
    <a
      href={`mailto:hello@clapesque.com?subject=Bonjour!&body=:)`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <BackgroundVideo src={video}>
        <VideoText>{`hello@clapesque.com`}</VideoText>
      </BackgroundVideo>
    </a>
    <div
      style={{
        marginTop: 30,
        textAlign: "center",
        lineHeight: `24px`,
        fontSize: 15,
      }}
    >
      <div>CLAPESQUE</div>
      <div>28 Rue Saint André Des Arts</div>
      <div>75006 PARIS</div>
      <div>(+33)766594431</div>
    </div>
  </Layout>
)

const VideoText = styled.div`
  font-size: 30px;
  color: black;
  padding: 15px;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 1100px) {
    font-size: 24px;
  }
`
